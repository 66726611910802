'use client';

import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import BlockUser from '@/components/BlockUser';
import Avatar from '@/components/ui/Avatar';
import Button from '@/components/ui/Button';
import ConfirmationPopup from '@/components/ui/ConfirmationPopup';
import { generateRoute, DEVELOPMENT, USER_TYPES, ROUTES } from '@/data';
import { useDimensions, useTranslations } from '@/hooks';
import { Link, useRouter } from '@/i18n/routing';
import { useChatStore } from '@/store';
export const ChatHeader = ({
  mobile
}) => {
  const {
    activeChat,
    archiveChat,
    blockUser,
    unblockUser,
    unarchiveChat,
    isChatArchived,
    deleteChat,
    loading
  } = useChatStore();
  const [archived, setArchived] = useState(activeChat?.status === 'archived');
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [ref, {
    height
  }] = useDimensions({});
  useEffect(() => {
    const chatWrapper = document.querySelector('.chat-wrapper');
    if (!chatWrapper) return;
    chatWrapper.style.setProperty('--chat-header-height', `${height}px`);
  }, [height]);
  useEffect(() => {
    setArchived(isChatArchived(activeChat?.sid));
  }, [activeChat]);
  const router = useRouter();
  const queryClient = useQueryClient();
  const t = useTranslations();
  const handleArchive = async () => {
    await archiveChat(queryClient, activeChat.sid);
    setArchived(true);
  };
  const handleUnarchive = async () => {
    await unarchiveChat(queryClient, activeChat.sid);
    setArchived(false);
  };
  const handleUserBlock = (isBlocked, spotifyId) => {
    if (isBlocked) {
      unblockUser(spotifyId);
    } else {
      blockUser(spotifyId);
    }
  };
  const handleDelete = async () => {
    await deleteChat(queryClient, activeChat.sid);
    setDeletePopupOpen(false);
    queryClient.invalidateQueries({
      queryKey: [QUERIES.CHAT.LIST]
    });
    router.push(ROUTES.CHAT);
  };
  const profileLink = generateRoute(ROUTES.CURATOR_DETAIL, {
    id: activeChat?.spotifyId
  });
  if (!activeChat) return null;
  return <div ref={ref} className="chat-header" data-sentry-component="ChatHeader" data-sentry-source-file="ChatHeader.jsx">
      <Link href={profileLink} className="inline-flex items-center gap-4 m-0 text-xl" data-sentry-element="Link" data-sentry-source-file="ChatHeader.jsx">
        {mobile && <Button variant="ghost" icon="ArrowLeft" onClick={() => router.back()} />}

        {activeChat?.avatar ? <Avatar src={activeChat.avatar} star={activeChat.type === USER_TYPES.STAR} /> : <Avatar star={activeChat.type === USER_TYPES.STAR} />}

        <span className="text-sm font-medium md:text-xl">
          {activeChat?.friendlyName}
        </span>
      </Link>

      <div className="flex items-center gap-2">
        {!archived ? <Button size="sm" variant="light" onClick={handleArchive} icon="Archive" loading={loading} label={t('common.actions.archive')} /> : <Button size="sm" variant="light" onClick={handleUnarchive} icon="Undo" loading={loading} label={t('common.actions.unarchive')} />}

        <BlockUser checkOnlineStatus spotifyId={activeChat?.spotifyId} showTitle={false} callback={handleUserBlock} data-sentry-element="BlockUser" data-sentry-source-file="ChatHeader.jsx" />

        {DEVELOPMENT && <>
            <ConfirmationPopup open={deletePopupOpen} onConfirm={handleDelete} loading={loading} onReject={() => setDeletePopupOpen(false)} title={t('feedback.prompts.delete.title')} text={t('feedback.prompts.delete.text')} />

            <Button size="sm" variant="red-light" onClick={() => setDeletePopupOpen(true)} icon="Trash" label={t('common.actions.delete')} />
          </>}
      </div>
    </div>;
};
export default ChatHeader;