import { useQueries } from '@tanstack/react-query';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { matchfyApi } from '@/api';
import Alert from '@/components/ui/Alert';
import Badge from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import Date from '@/components/ui/Date';
import Spinner from '@/components/ui/Spinner';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/Tooltip/Tooltip';
import { useConsent } from '@/context/ConsentProvider';
import { QUERIES } from '@/data/queries';
import { useTranslations } from '@/hooks';
import { usePlaylistStore } from '@/store';
import { cn } from '@/utils';
export const PlaylistGenres = ({
  className,
  closeModalFn
}) => {
  const [loading, setLoading] = useState(false);
  const {
    currentPlaylistId,
    requestPlaylistGenresUpdate,
    updatePlaylistGenres
  } = usePlaylistStore();
  const {
    handleIntercomToggle
  } = useConsent();
  const t = useTranslations();
  const [playlistGenres, isRecentlyUpdated] = useQueries({
    queries: [{
      queryKey: [QUERIES.PLAYLIST.GENRES, currentPlaylistId],
      queryFn: () => matchfyApi.getPlaylistGenres(currentPlaylistId)
    }, {
      queryKey: [QUERIES.PLAYLIST.GENRES_CHECK_UPDATE, currentPlaylistId],
      queryFn: () => matchfyApi.getPlaylistGenresUpdatedAt(currentPlaylistId)
    }]
  });
  const handleTagsUpdate = async () => {
    setLoading(true);
    try {
      await requestPlaylistGenresUpdate(currentPlaylistId);
      closeModalFn();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const classes = cn({
    'field-input-container': true,
    'field-icon-before': true,
    [className]: className
  });
  const ButtonWrapper = ({
    children
  }) => {
    if (!isRecentlyUpdated?.data?.status) {
      return children;
    }
    return <Tooltip data-sentry-element="Tooltip" data-sentry-component="ButtonWrapper" data-sentry-source-file="PlaylistGenres.jsx">
        <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="PlaylistGenres.jsx">
          {children}
        </TooltipTrigger>
        <TooltipContent className="max-w-[280px]" data-sentry-element="TooltipContent" data-sentry-source-file="PlaylistGenres.jsx">
          <span>{t('playlist.limits.genres_update')}</span>
        </TooltipContent>
      </Tooltip>;
  };
  if (playlistGenres?.isLoading) {
    return <Spinner />;
  }
  return <div className={classes} data-sentry-component="PlaylistGenres" data-sentry-source-file="PlaylistGenres.jsx">
      <div className="w-full mt-3">
        {playlistGenres?.data && playlistGenres?.data?.genres?.length > 0 && <div className="tags">
            {playlistGenres?.data?.genres.map(genre => {
          return <span key={genre} className="p-0 capitalize tag">
                  <span className="px-4">{genre}</span>
                </span>;
        })}
          </div>}
      </div>

      {playlistGenres?.data && playlistGenres?.data?.latestUpdate && <Badge className="mt-4 mb-5 text-base" icon="Clock" vertical={isMobile ? true : false} variant="blue-light" label={t('common.time.last_update')} value={<Date dateString={playlistGenres?.data?.latestUpdate} includeTime={true} />} />}

      {playlistGenres?.data?.genres?.length === 0 && isRecentlyUpdated?.data?.status && <Alert className="mb-4" variant="primary-light" label={t('empty_state.content.genres.no_data')} cta={<Button size="sm" icon="CircleHelp" className="toggle-intercom" onClick={handleIntercomToggle} label={t('support.page.title')} />} />}

      <ButtonWrapper data-sentry-element="ButtonWrapper" data-sentry-source-file="PlaylistGenres.jsx">
        <Button className="w-full mt-3" onClick={handleTagsUpdate} loading={loading} disabled={isRecentlyUpdated?.data?.status} data-sentry-element="Button" data-sentry-source-file="PlaylistGenres.jsx">
          {t('playlist.settings.genres.recalculate')}
        </Button>
      </ButtonWrapper>
    </div>;
};
export default PlaylistGenres;