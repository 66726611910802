'use client';

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { matchfyApi } from '@/api';
import Alert from '@/components/ui/Alert';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/Dialog';
import { RangeSlider } from '@/components/ui/Field';
import PaymentGateway from '@/components/ui/Payment/PaymentGateway';
import Spinner from '@/components/ui/Spinner';
import { PLAYLIST_PROMOTION_MAX_WEEK, PLAYLIST_PROMOTION_MIN_WEEK, STRIPE_PRODUCTS } from '@/data';
import { useTranslations } from '@/hooks';
const PlaylistPromote = ({
  playlist,
  isFeatured,
  open,
  onClose
}) => {
  const [playlistFeaturedPrice, setPlaylistFeaturedPrice] = useState(0);
  const [timeToEnd, setTimeToEnd] = useState(0);
  const [timeToEndUnit, setTimeToEndUnit] = useState('days');
  const [duration, setDuration] = useState(PLAYLIST_PROMOTION_MAX_WEEK / 2);
  const t = useTranslations();
  const marks = {};
  for (let i = PLAYLIST_PROMOTION_MIN_WEEK; i <= PLAYLIST_PROMOTION_MAX_WEEK; i++) {
    marks[i] = i;
  }
  const {
    data,
    isLoading,
    isSuccess
  } = useQuery({
    queryKey: ['playlistFeaturedPrice'],
    queryFn: () => matchfyApi.getPlaylistFeaturedPrice()
  });
  useEffect(() => {
    if (isSuccess) {
      setPlaylistFeaturedPrice(data.playlistFeaturedPrice);
    }
  }, [isSuccess, data]);
  useEffect(() => {
    if (playlist?.featuredEndsAt) {
      let timeLeft = dayjs(playlist.featuredEndsAt).diff(dayjs(), 'days');
      if (timeLeft === 0) {
        timeLeft = dayjs(playlist.featuredEndsAt).diff(dayjs(), 'hours');
        setTimeToEndUnit('hours');
      }
      setTimeToEnd(timeLeft);
    }
  }, [playlist, isFeatured]);
  return <Dialog open={open} onOpenChange={onClose} data-sentry-element="Dialog" data-sentry-component="PlaylistPromote" data-sentry-source-file="PlaylistPromote.jsx">
      <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="PlaylistPromote.jsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="PlaylistPromote.jsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="PlaylistPromote.jsx">{t('playlist.promote.title')}</DialogTitle>
        </DialogHeader>

        <p className="mb-5">
          {t('playlist.promote.description', {
          shouldParse: true
        })}
        </p>

        {isLoading && <Spinner />}

        {!isFeatured && !isLoading && playlistFeaturedPrice > 0 && <div className="mb-5">
            <RangeSlider variant="green" className="mb-5" label={t('playlist.promote.duration')} min={PLAYLIST_PROMOTION_MIN_WEEK} max={PLAYLIST_PROMOTION_MAX_WEEK} value={duration} marks={marks} onChange={setDuration} step={1} />

            <PaymentGateway btnLabel={t('playlist.promote.cta')} plan={STRIPE_PRODUCTS.PLAYLIST_FEATURED} planName={'playlist_featured'} price={duration * playlistFeaturedPrice} payload={{
          duration: duration,
          playlistId: playlist.playlistId
        }} mode="payment" creditsDiscount={0.8} onClose={() => setOpen(false)} />
          </div>}

        {isFeatured && timeToEnd > 0 && <Alert variant="success" className="text-center">
            <p className="md:text-xl">
              {t('playlist.featured.timer.text')}
              {timeToEndUnit === 'days' ? t('playlist.featured.timer.unit.days', {
            timeLeft: timeToEnd
          }) : t('playlist.featured.timer.unit.hours', {
            timeLeft: timeToEnd
          })}
            </p>
          </Alert>}
      </DialogContent>
    </Dialog>;
};
export default PlaylistPromote;