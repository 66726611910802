import { AnimatePresence, motion } from 'framer-motion';
import { ChatItem } from '@/components/Chat';
import Empty from '@/components/ui/Empty';
import { useTranslations } from '@/hooks';
export const ChatActive = ({
  activeSid,
  showArchive,
  handleChatSelect,
  chatList,
  isLoading
}) => {
  const t = useTranslations();
  return <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-component="ChatActive" data-sentry-source-file="ChatActive.jsx">
      {!showArchive && chatList?.length > 0 && chatList.map(chat => <motion.div layout className="w-full" key={chat.sid}>
            <ChatItem key={chat.sid} active={activeSid === chat.sid} chat={chat} onClick={handleChatSelect} />
          </motion.div>)}

      {!showArchive && !isLoading && chatList?.length === 0 && <Empty ghost size="sm" className="h-full m-0" message={t('empty_state.chat.no_chats')} icon="Inbox" />}
    </AnimatePresence>;
};
export default ChatActive;