'use client';

import { useEffect } from 'react';
import Badge from '@/components/ui/Badge';
import Button from '@/components/ui/Button';
import { PLANS, RECENT_MATCH_LIMIT } from '@/data';
import { useTranslations } from '@/hooks';
import { useMatchStore, useRecentMatchStore } from '@/store';
import { cn } from '@/utils';
export const MatchRecent = ({
  artists,
  plan,
  className,
  onClick
}) => {
  const {
    clearMatches,
    recentMatches,
    removeRecentMatch
  } = useRecentMatchStore();
  const {
    matchType
  } = useMatchStore();
  const t = useTranslations();
  const handleClick = match => {
    if (onClick) {
      onClick(match);
    }
  };
  const classes = cn({
    'match-recent': true,
    [className]: className
  });
  useEffect(() => {
    if (plan !== PLANS.AGENCY && recentMatches?.length > 0 && artists?.length > 0) {
      recentMatches.forEach(match => {
        const trackArtistIds = match?.track?.artists?.map(artist => artist.id);
        if (trackArtistIds?.length > 0 && match?.track?.id && !artists.some(artist => trackArtistIds.includes(artist.id))) {
          removeRecentMatch(match.track.id);
        }
      });
    }
  }, [artists, plan]);
  if (!recentMatches || !recentMatches.filter(m => m.type === matchType).length) {
    return null;
  }
  return <div className={classes} data-sentry-component="MatchRecent" data-sentry-source-file="MatchRecent.jsx">
      <h5 className="text-xl">{t('match.common.recent.title')}</h5>

      <div className="flex flex-wrap items-center gap-2">
        {recentMatches.filter(match => match.type === matchType).slice(0, RECENT_MATCH_LIMIT).map(match => <Badge key={match?.track?.id} variant="light" className="match-recent-item hoverable" label={match?.track?.name?.length > 32 ? `${match?.track?.name?.slice(0, 32)}...` : match?.track?.name} image={match?.track?.album?.images?.[0]?.url} clearable onClear={() => removeRecentMatch(match?.track?.id)} onClick={() => handleClick(match)} />)}

        <Button size="sm" variant="ghost" onClick={clearMatches} label={t('match.common.recent.clear_all')} data-sentry-element="Button" data-sentry-source-file="MatchRecent.jsx" />
      </div>
    </div>;
};
export default MatchRecent;