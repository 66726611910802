'use client';

import { signIn } from "next-auth/react";
import { useState } from 'react';
import Button from '@/components/ui/Button';
import Card from '@/components/ui/Card';
import { LogoIconColor } from '@/components/ui/Logo/Logo';
import { ROUTES } from '@/data';
import { useTranslations, useActiveRoute } from '@/hooks';
import { useAppStore } from '@/store';
import { cn } from '@/utils';
export const AuthCard = ({
  className
}) => {
  const [loading, setLoading] = useState(false);
  const t = useTranslations();
  const {
    redirect
  } = useAppStore();
  const {
    getRoutePath
  } = useActiveRoute();
  const handleSignIn = () => {
    setLoading(true);
    const currentPath = getRoutePath(redirect);
    const redirectPath = !currentPath ? ROUTES.DASHBOARD : redirect;
    const callbackUrl = redirectPath || ROUTES.DASHBOARD;
    signIn('matchfy', {
      callbackUrl
    });
  };
  return <div className={cn("flex flex-col items-center justify-center gap-4", className)} data-sentry-component="AuthCard" data-sentry-source-file="AuthCard.jsx">
      <Card className="sm:max-w-[425px] border-outline border shadow-lg" data-sentry-element="Card" data-sentry-source-file="AuthCard.jsx">
        <div className="flex flex-col items-center justify-center px-5 py-12">
          <div className="flex flex-col items-center justify-center gap-4 mb-8">
            <LogoIconColor className="w-24 h-24" data-sentry-element="LogoIconColor" data-sentry-source-file="AuthCard.jsx" />
            <h1 className="text-2xl font-semibold text-center md:text-3xl text-balance">
              {t('common.labels.welcome_to_matchfy')}
            </h1>
          </div>

          <Button size="lg" icon="Spotify" iconType="brand" label={`Login ${t('auth.login.with_spotify')}`} loading={loading} onClick={handleSignIn} data-sentry-element="Button" data-sentry-source-file="AuthCard.jsx" />

          <p className="text-sm opacity-80 text-center text-balance max-w-[90%] mt-6">
            {t('auth.login.terms', {
            shouldParse: true
          })}
          </p>
        </div>
      </Card>

      <div className="flex flex-col items-center justify-center gap-4">
        <Button href={ROUTES.HOME} variant="ghost" icon="ArrowLeft" labelClasses="font-normal opacity-70" data-sentry-element="Button" data-sentry-source-file="AuthCard.jsx">
          {t('empty_state.not_found.cta')}
        </Button>
      </div>
    </div>;
};
export default AuthCard;