'use client';

import { useEffect, useRef, useState } from 'react';
import { SearchSelect } from '@/components/Search';
import Button from '@/components/ui/Button';
import { useDimensions, useTranslations } from '@/hooks';
import { useChatStore } from '@/store';
import { cn } from '@/utils';
export const ChatInput = ({
  onSend
}) => {
  const MESSAGE_MIN_LENGTH = 2;
  const {
    activeSid,
    isCurrentUserBlocked,
    chats
  } = useChatStore();
  const [addPlaylist, setAddPlaylist] = useState(false);
  const [addTrack, setAddTrack] = useState(false);
  const [track, setTrack] = useState(null);
  const [playlist, setPlaylist] = useState(null);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [clear, setClear] = useState(false);
  const t = useTranslations();
  const [ref, {
    height
  }] = useDimensions({});
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [activeSid]);
  useEffect(() => {
    const chatWrapper = document.querySelector('.chat-wrapper');
    if (!chatWrapper) return;
    chatWrapper.style.setProperty('--chat-footer-height', `${height}px`);
  }, [height]);
  useEffect(() => {
    if (message.length > MESSAGE_MIN_LENGTH) {
      setError(false);
    }
  }, [message]);
  const handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey && message?.length > 0) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };
  const resetData = () => {
    setMessage('');
    setClear(true);
    setAddPlaylist(false);
    setPlaylist(null);
    setAddTrack(false);
    setTrack(null);
  };
  const handleSendMessage = async e => {
    e.preventDefault();
    const trimmedMessage = message.trim();
    if (!message || message.length < MESSAGE_MIN_LENGTH || trimmedMessage.length < MESSAGE_MIN_LENGTH) {
      setError(true);
      return;
    }
    if (message && chats[activeSid]) {
      let attributes = {};
      if (track) {
        attributes.track = track;
      }
      if (playlist) {
        attributes.playlist = playlist;
      }
      await chats[activeSid].sendMessage(message, attributes);
      resetData();
      if (onSend) {
        onSend(message);
      }
    }
  };
  const handleAttributeChange = (value, type) => {
    if (type === 'track') {
      setTrack({
        id: value.id,
        url: value.external_urls.spotify,
        name: value.name,
        type: 'track',
        icon: 'Music',
        image: value.album.images[0].url
      });
    }
    if (type === 'playlist') {
      setPlaylist({
        id: value.id,
        url: value.external_urls.spotify,
        name: value.name,
        type: 'playlist',
        icon: 'ListMusic',
        image: value.images[0].url
      });
    }
  };
  return <div ref={ref} className="chat-footer" data-sentry-component="ChatInput" data-sentry-source-file="ChatInput.jsx">
      <form className="w-full" onSubmit={handleSendMessage}>
        <textarea ref={inputRef} disabled={isCurrentUserBlocked()} value={message} className={cn({
        'border-red-500': error
      })} onChange={e => setMessage(e.target.value)} onKeyDown={handleKeyDown} placeholder={t('field.chat.message.placeholder')} />

        {error && <span className="text-xs text-red-500">
            {t('feedback.error.validation.enter_message')}
          </span>}

        <div className="flex flex-col items-end justify-between gap-3 md:flex-row">
          <div className="flex flex-col items-center w-full gap-2 md:flex-row md:w-auto">
            {!addPlaylist && <Button size="sm" variant="light" icon="ListMusic" disabled={isCurrentUserBlocked()} className="flex-1 w-full whitespace-nowrap" label={t('common.actions.add', {
            entity: 'playlist'
          })} onClick={() => setAddPlaylist(true)} />}

            {addPlaylist && <SearchSelect entity="playlist" className="w-full" name="playlist" hideLabel={true} onDataChange={value => handleAttributeChange(value, 'playlist')} isDisabled={isCurrentUserBlocked()} clear={clear} onClear={() => setAddPlaylist(false)} />}

            {!addTrack && <Button size="sm" variant="light" icon="Music" disabled={isCurrentUserBlocked()} className="flex-1 w-full whitespace-nowrap" label={t('common.actions.add', {
            entity: 'track'
          })} onClick={() => setAddTrack(true)} />}

            {addTrack && <SearchSelect entity="track" className="w-full" name="track" hideLabel={true} onDataChange={value => handleAttributeChange(value, 'track')} isDisabled={isCurrentUserBlocked()} clear={clear} onClear={() => setAddTrack(false)} />}
          </div>

          <Button type="submit" disabled={isCurrentUserBlocked()} className="w-full md:w-auto" label={t('common.actions.send', {
          entity: 'message'
        })} icon="ArrowRight" data-sentry-element="Button" data-sentry-source-file="ChatInput.jsx" />
        </div>
      </form>
    </div>;
};
export default ChatInput;